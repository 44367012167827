import {
  createRouter,
  createWebHashHistory,
  RouteRecordRaw
} from 'vue-router'

// * 导入所有router

// 基础模块
const inspectionRouters = import.meta.globEager('./modules/inspection/*.ts')
// const orderRouters = import.meta.globEager('./modules/order/*.ts')
const nursingRouters = import.meta.globEager('./modules/nursing/*.ts')
const sampleRouters = import.meta.globEager('./modules/sample/*.ts')
const reportRouters = import.meta.globEager('./modules/report/*.ts')
const referralsRouters = import.meta.globEager('./modules/referrals/*.ts')
// const userRouters = import.meta.globEager('./modules/user/*.ts')
const accountRouters = import.meta.globEager('./modules/account/*.ts')
const paymentsRouters = import.meta.globEager('./modules/payments/*.ts')
const verificationRouters = import.meta.globEager('./modules/verification/*.ts')
const doctorRouters = import.meta.globEager('./modules/doctor/*.ts')
const mallRouters = import.meta.globEager('./modules/mall/*.ts')

const metaRouters = Object.assign(
  inspectionRouters,
  // orderRouters,

  verificationRouters,
  sampleRouters,
  reportRouters,
	 nursingRouters,
  referralsRouters,
  // userRouters,
  doctorRouters,
  mallRouters,
  paymentsRouters,
  accountRouters,

)
// * 处理路由
export const routerArray: RouteRecordRaw[] = []
Object.keys(metaRouters).forEach(item => {
  Object.keys(metaRouters[item]).forEach((key: any) => {
    routerArray.push(...metaRouters[item][key])
  })
})

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: { name: 'Home' }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      requiresAuth: false,
      title: '登录页',
      key: 'login'
    }
  },
  {
    path: '/index',
    name: 'Home',
    component: () => import('@/views/app/index.vue'),
    meta: {
      requiresAuth: true,
      title: '首页',
      key: 'home'
    }
  },
  {
    path: '/report/pdf',
    name: 'reportPDF',
    component: () => import('@/views/app/report/detail/pdf.vue')
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/components/ErrorMessage/403.vue'),
    meta: {
      requiresAuth: true,
      title: '403页面',
      key: '403'
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/components/ErrorMessage/404.vue'),
    meta: {
      requiresAuth: false,
      title: '404页面',
      key: '404'
    }
  },
  {
    path: '/500',
    name: '500',
    component: () => import('@/components/ErrorMessage/500.vue'),
    meta: {
      requiresAuth: false,
      title: '500页面',
      key: '500'
    }
  },
  ...routerArray,
  {
    // 找不到路由重定向到404页面
    path: '/:pathMatch(.*)',
    redirect: { name: '404' }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  strict: false,
  // 切换页面，滚动到最顶部
  scrollBehavior: () => ({ left: 0, top: 0 })
})

export default router
